



































import type { Cta } from '@/inc/types'

import { defineComponent, PropType, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { pushCta, GtmCta } from '@/inc/utils'

export default defineComponent({
  name: 'CtaText',
  props: {
    content: {
      type: Object as PropType<Cta>,
      required: true,
    },
  },
  setup(props) {
    const rootElRef = ref<HTMLElement | null>(null)

    // Push event to datalayer on click
    const pushDatalayer = () => {
      if (!rootElRef.value) {
        return
      }

      const ctaLabel = (props.content.link.label ||
        props.content.link.title) as string

      const layer: Partial<GtmCta> = {
        ctaLabel,
        ctaType: 'text_left',
        ctaUrl: props.content.link.url,
      }

      pushCta(layer, rootElRef.value)
    }

    return {
      rootElRef,
      pushDatalayer,
      ...useGetters(['meta']),
    }
  },
})
